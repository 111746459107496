import { Subject } from 'rxjs';
import { LocalDataSource } from 'ng2-smart-table';
import { OnInit, OnDestroy } from '@angular/core';
import { CollectionView } from 'wijmo/wijmo';

// Base component for grid pages
export abstract class BasePagingComponent<T> implements OnInit, OnDestroy {
  public source: LocalDataSource = new LocalDataSource();
  public data: CollectionView;
  public startDate: number;
  public endDate: number;
  public initialLoaded: boolean = false;
  public allDataLoaded: boolean = false;
  public gridSettings: any;
  public errorMessage: string = '';
  public loadingMessage: string = '';

  public customerId: string = '';
  public kioskToken: string = '';

  protected cancellationToken: Subject<any> = new Subject();
  protected loadInitialPage$: Subject<any> = new Subject();
  protected loadBackgroundPage$: Subject<T> = new Subject();
  protected _totalPages: number[] = [];
  protected readonly maxWaitTime: number = 60000;
  protected maxDataRecords: number = 3000;
  protected readonly pageSize: number = 1000;

  public ngOnInit(): void {}

  public ngOnDestroy(): void {
    this.source.empty();
    this.cancellationToken.next();
    this.cancellationToken.complete();
    this.loadInitialPage$.complete();
    this.loadBackgroundPage$.complete();
  }

  protected resetPage(): void {
    this.initialLoaded = false;
    this.allDataLoaded = false;
    this._totalPages = [];
    this.loadingMessage = '';
  }

  public startDateChanged(newStartDate: number): void {
    this.startDate = newStartDate;
    this.loadInitialPage$.next();
  }

  public endDateChanged(newEndDate: number): void {
    this.endDate = newEndDate;
    this.loadInitialPage$.next();
  }
}
